import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { 
  CookieService,
  User
} from '@smartviser/core-lib';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.less']
})
export class MyAccountComponent {
  @Input() customerIdInput!: number;
  formGroup: FormGroup;
  userConnected?:  User | null;
  
  constructor(private cookieService: CookieService, private userService: UserService){
    this.userConnected = this.cookieService.getUserConnected();
    this.formGroup = new FormGroup({
      name: new FormControl({value: this.userConnected?.name, disabled: true}, [Validators.required]),
      surname: new FormControl({value: this.userConnected?.surname, disabled: true}, [Validators.required]),
      email: new FormControl({value: this.userConnected?.email, disabled: true}, [Validators.required, Validators.email]),
      username: new FormControl({value: this.userConnected?.username, disabled: true}, [Validators.required])
    });
  }

  submit = (): void => {
    const userToSend: User = {
      ...this.userConnected as User,
      name: this.formGroup.get('name')?.value,
      surname: this.formGroup.get('surname')?.value,
      email: this.formGroup.get('email')?.value,
      username: this.formGroup.get('username')?.value
    };
  };
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utils/api.service';
import { environment } from '../../../environments/environment';
import { Customer } from '../interfaces/customer.interface';
import { Layer3Imei } from '../interfaces/layer3-logs.interface';
import { Layer3Log } from '../interfaces/layer3-imei.interface';


@Injectable({
  providedIn: 'root'
})
export class L3Service {

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getListL3Customers = (): Observable<Customer[]> => this.http.get<Customer[]>(
    `${environment.baseUrl}/l3/customers`, 
    this.apiService.generateAuthHeaderV2());

  getListL3Imei = (customer_id: number): Observable<Layer3Imei[]> => this.http.get<Layer3Imei[]>(
    `${environment.baseUrl}/l3/imei/${customer_id}`, 
    this.apiService.generateAuthHeaderV2());

  removeImei = (imei: string): Observable<void> => this.http.delete<void>(
    `${environment.baseUrl}/l3/imei/${imei}`, 
    this.apiService.generateAuthHeaderV2());

  addImei = (imei: Layer3Imei): Observable<string> => this.http.post<string>(
    `${environment.baseUrl}/l3/imei`, imei,
    this.apiService.generateAuthHeaderV2());

  getListL3Logs = (): Observable<Layer3Log[]> => this.http.get<Layer3Log[]>(
    `${environment.baseUrl}/l3/logs`, 
    this.apiService.generateAuthHeaderV2());
}

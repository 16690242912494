import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utils/api.service';
import { environment } from '../../../environments/environment';
import { CustomerFiles, CustomerStorageInfo } from '../interfaces/storage.interface';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getCustomersFilesList = (customerId: number): Observable<CustomerFiles[]> => this.http.get<CustomerFiles[]>(
    `${environment.baseUrl}/customer/${customerId}/documents`, 
    this.apiService.generateAuthHeaderV2());

  getStorageCustomerInfo = (customerId: number): Observable<CustomerStorageInfo> => this.http.get<CustomerStorageInfo>(
    `${environment.baseUrl}/customer/${customerId}/storage`, 
    this.apiService.generateAuthHeaderV2());
  
  updateStorageCustomer = (customerId: number, storageId: number, newSize: number): Observable<string> => this.http.put<string>(
    `${environment.baseUrl}/customer/${customerId}/storage/${storageId}`, {newSize},
    this.apiService.generateAuthHeaderV2());
  
  uploadCustomerFile = (customerId: number, file: FormData): Observable<string> => this.http.post<string>(
    `${environment.baseUrl}/customer/${customerId}/document`, file,
    this.apiService.generateAuthHeaderV2File());

  downloadCustomerFile(customerId: number, documentId: number): Observable<any> {
    const headers: {headers: HttpHeaders} = this.apiService.generateAuthHeaderV2();
    return this.http.get<any>(`${environment.baseUrl}/customer/${customerId}/documents/${documentId}`, {
      responseType: 'arraybuffer' as 'json', headers: headers.headers}
    );
  }
  
  deleteCustomerFile = (customerId: number, documentId: number): Observable<string> => this.http.delete<string>(
    `${environment.baseUrl}/customer/${customerId}/documents/${documentId}`, 
    this.apiService.generateAuthHeaderV2());
}

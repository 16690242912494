import { Component } from '@angular/core';
import { Customer } from 'src/app/admin/interfaces/customer.interface';


@Component({
  selector: 'app-admin-sv-page',
  templateUrl: './admin-sv-page.component.html',
  styleUrls: ['./admin-sv-page.component.less']
})
export class AdminSvPageComponent{

  openedSidenav: boolean = false;
  customer!: Customer;

  openCustomerSidenav($event: Customer): void{
    this.customer = $event;
  }
}

<h3>Customers with Layer 3</h3>
<table mat-table [dataSource]="customersList" class="table-margin width-90">
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let customer">
      {{customer.name}}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;let imei;" class="mat-row-imei">
  </tr>
</table>

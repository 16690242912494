<h1 mat-dialog-title>Edit Licence</h1>
<div mat-dialog-content>
  <div class="column">
    <mat-form-field *ngIf="!isEdit" class="example-full-width" appearance="fill">
      <mat-label>Number of licence to add</mat-label>
      <input matInput min="0" type="number" [(ngModel)]="licence.nbLicence">
    </mat-form-field>
    <mat-form-field *ngIf="!isEdit"  appearance="fill">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker" disabled>
        <input matStartDate [(ngModel)]="licence.startDate" placeholder="Start date">
        <input matEndDate [(ngModel)]="licence.endDate" placeholder="End date">
      </mat-date-range-input>
      <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
    </mat-form-field>    
    <mat-form-field *ngIf="isEdit"  appearance="fill">
      <mat-label>Enter end date</mat-label>
      <input matInput [(ngModel)]="licence.endDate" [matDatepicker]="picker" disabled placeholder="End date">
      <mat-hint>DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker disabled="false"></mat-datepicker>
    </mat-form-field>   
    <mat-checkbox color="primary" [(ngModel)]="licence.synchroviser" [checked]="licence.synchroviser">
      Synchroviser
    </mat-checkbox>
    <mat-checkbox color="primary" [(ngModel)]="licence.mos" [checked]="licence.mos">
      MOS
    </mat-checkbox>
    <mat-checkbox color="primary" [(ngModel)]="licence.proxy" [checked]="licence.proxy">
      Proxy
    </mat-checkbox>
    <mat-checkbox color="primary" [(ngModel)]="licence.auxCall" [checked]="licence.auxCall">
      Aux call
    </mat-checkbox>
    <mat-form-field appearance="fill">
      <mat-label>Authorized Versions</mat-label>
      <mat-select [(ngModel)]="licence.authorizedVersions" multiple [compareWith]="compareVersion">
        <mat-option *ngFor="let aV of authorizedV" [value]="aV">
          {{aV.version}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null">No Thanks</button>
  <button mat-button [mat-dialog-close]="licence" cdkFocusInitial>Ok</button>
</div>

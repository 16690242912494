<h1 mat-dialog-title>Manage Version</h1>
<div mat-dialog-content>
  <div class="column">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>New Version</mat-label>
      <input matInput [formControl]="versionFormControl" [(ngModel)]="newVersion">
    </mat-form-field>
    <button mat-button [disabled]="versionFormControl.hasError('required')" (click)="addVersion()" cdkFocusInitial>Add Version</button>
  </div>
  <mat-nav-list>
    <mat-list-item *ngFor="let aV of authorizedVersion">
       <p matListItemTitle>{{ aV.version }}</p>
       <button mat-icon-button (click)="deleteVersion(aV.id)" matListItemMeta>
          <mat-icon>delete</mat-icon>
       </button>
    </mat-list-item>
  </mat-nav-list>
  
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null">Close</button>
</div>

<h1 mat-dialog-title>User</h1>
<div mat-dialog-content>
  <div class="column" *ngIf="user">
    <mat-form-field appearance="fill">
      <mat-label>Role</mat-label>
      <mat-select [(ngModel)]="user.roles" [compareWith]="compareName" required [formControl]="selectFormControl" (selectionChange)="selectRoles()">
        <mat-option *ngFor="let r of rolesList" [value]="r">
          {{r.role}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput [formControl]="nameFormControl" [(ngModel)]="user.name">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Surname</mat-label>
      <input matInput [formControl]="surnameFormControl" [(ngModel)]="user.surname">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Email</mat-label>
      <input matInput type="email" [formControl]="emailFormControl" [(ngModel)]="user.email">
    </mat-form-field>
    <div class="example-full-width" appearance="fill">
      VWS Services
      <ul class="list-select">
        <li *ngFor="let vwsS of vwsServicesList">
          <mat-checkbox color="primary" [checked]="hasVwsService(vwsS)" (change)="setVwsService(vwsS)">
            {{vwsS.name.replace('vws_', '')}}
          </mat-checkbox>
        </li>
      </ul>
    </div>
  </div>
  <mat-checkbox color="primary" *ngIf="!isEdit && user.sendEmail != undefined" [(ngModel)]="user.sendEmail" [checked]="user.sendEmail">
    Send an email to the user in order to create his password
  </mat-checkbox>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null">No Thanks</button>
  <button mat-button *ngIf="!isEdit" 
  [disabled]="emailFormControl.hasError('email') || emailFormControl.hasError('required') || nameFormControl.hasError('required') || surnameFormControl.hasError('required') || surnameFormControl.hasError('selectFormControl')" 
  [mat-dialog-close]="user" cdkFocusInitial>Ok</button>
  <button mat-button *ngIf="isEdit" [mat-dialog-close]="user" cdkFocusInitial
  [disabled]="nameFormControl.hasError('required') || surnameFormControl.hasError('required') || surnameFormControl.hasError('selectFormControl')">Ok</button>
</div>

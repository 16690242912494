<div *ngIf="customer" class="column-80 customer-tab">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Number of licence</mat-label>
      <input matInput min="0" type="number" [(ngModel)]="customer.nbLicence">
      <mat-hint *ngIf="isNotValidnumberLicence()">Number of licences should be equal or superior of licences already created</mat-hint>
    </mat-form-field>
    <mat-checkbox color="primary" [(ngModel)]="customer.status">Status Active</mat-checkbox>
    <mat-form-field appearance="fill">
      <mat-label>Status</mat-label>
      <mat-select [(ngModel)]="customer.businessStatus" [compareWith]="compareId">
        <mat-option *ngFor="let s of statusList" [value]="s">
          {{s.status}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Category</mat-label>
      <mat-select [(ngModel)]="customer.category" [compareWith]="compareId">
        <mat-option *ngFor="let c of categoryList" [value]="c">
          {{c.category}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Mos Server</mat-label>
      <mat-select [(ngModel)]="customer.mosServer" [compareWith]="compareId">
        <mat-option *ngFor="let m of mosServerList" [value]="m">
          {{m.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

<button mat-raised-button color="primary" [disabled]="isNotValidnumberLicence()" (click)="updateCustomer()" cdkFocusInitial>Update Customer</button>
</div>
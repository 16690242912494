import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UiService } from 'src/app/utils/ui.service';
import { AuthorizedVersion } from '../../interfaces/licence.interface';
import { LicencesService } from '../../services/licences.service';
@Component({
  selector: 'version-dialog',
  templateUrl: './version-dialog.component.html',
  styleUrls: ['./version-dialog.component.less']
})
export class VersionDialogComponent implements OnInit{

  authorizedVersion: AuthorizedVersion[] = [];
  newVersion: string = '';
  versionFormControl: FormControl = new FormControl('', [Validators.required]);

  constructor(public dialogRef: MatDialogRef<VersionDialogComponent>, 
              private licencesService: LicencesService, private uiService: UiService) {
  }

  ngOnInit(): void {
    this.getVersions();
  }

  addVersion(): void {
    this.licencesService.addVersions(this.newVersion).subscribe({
      next : (mesage: string) => {
        this.uiService.openSnackBar(mesage, undefined);
        this.getVersions();
      },
      error: () => {
        this.uiService.openSnackBar('Can not add Version', undefined);
      }}
    );
  }

  deleteVersion(versionId: number): void {
    this.licencesService.deleteVersions(versionId).subscribe({
      next : (mesage: string) => {
        this.uiService.openSnackBar(mesage, undefined);
        this.getVersions();
      },
      error: () => {
        this.uiService.openSnackBar('Can not add Version', undefined);
      }}
    );

  }

  getVersions(): void {
    this.licencesService.getVersions().subscribe({
      next : (authorizedVersion: AuthorizedVersion[]) => {
        this.authorizedVersion = authorizedVersion;
      },
      error: () => {
        this.uiService.openSnackBar('Error fetching authorized version', undefined);
      }}
  );
}

}

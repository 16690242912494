import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number, format: string = 'h m s i'): string {
    let h: number = 0;
    let hString: string = '';
    let m: number = 0;
    let mString: string = '';
    let s: number = 0;
    let sString: string = '';
    const ms: number = value % 1000;
    let iString: string = '';

    value = value / 1000;

    if(value > 3600) {
      h = value / 3600;
      value %= 3600;
    }
    if(value > 60) {
      m = value / 60;
      value %= 60;
    }
        
    s = value ;

    // eslint-disable-next-line sonarjs/no-nested-template-literals
    hString = `${h ? `${Math.trunc(h)}h` : ''}`;
    // eslint-disable-next-line sonarjs/no-nested-template-literals
    mString = `${m ? `${Math.trunc(m)}m` : ''}`;
    // eslint-disable-next-line sonarjs/no-nested-template-literals
    sString = `${s ? `${Math.trunc(s)}s` : ''}`;
    // eslint-disable-next-line sonarjs/no-nested-template-literals
    iString = `${ms ? `${Math.trunc(ms)}ms` : ''}`;

    return format
      .replace('h', hString)
      .replace('m', mString)
      .replace('s', sString)
      .replace('i', iString);

  }

}

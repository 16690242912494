import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}

  openSnackBar(message: string, action: string|undefined, config?: MatSnackBarConfig): void {
    config = config ? config : {};
    config.duration = config.duration || 2000;
    config.horizontalPosition = config.horizontalPosition || 'center';
        
    this.snackBar.open(message, action, config);
  }
}

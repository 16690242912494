import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'update-storage-dialog',
  templateUrl: './update-storage-dialog.component.html',
  styleUrls: ['./update-storage-dialog.component.less']
})
export class UpdateStorageDialogComponent implements OnInit{
  newSize: number = 0;

  constructor(public dialogRef: MatDialogRef<UpdateStorageDialogComponent>, @Inject(MAT_DIALOG_DATA) public size: number, public editDialog: MatDialog) {
  }

  ngOnInit(): void {
    this.newSize = this.size;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utils/api.service';
import { environment } from '../../../environments/environment';
import { ActiveLicence, AuthorizedVersion, ConnectionCustomerHistory, ConnectionHistory, Licence, MultipleLicence, SvKey, SvKeyType } from '../interfaces/licence.interface';


@Injectable({
  providedIn: 'root'
})
export class LicencesService {

  private nbLicence: number = 0;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getSvKeys = (customerId: number): Observable<SvKey[]> => this.http.get<SvKey[]>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/sv-keys`, this.apiService.generateAuthHeaderV1());
  
  updateSvKeys = (customerId: number, svKey: SvKey): Observable<SvKey> => this.http.put<SvKey>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/sv-keys/${svKey.id}`, {data: JSON.stringify(svKey)}, this.apiService.generateAuthHeaderV1());
  
  addSvKeys = (customerId: number, svKey: SvKey): Observable<SvKey> => this.http.post<SvKey>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/sv-keys`, {data: JSON.stringify(svKey)}, this.apiService.generateAuthHeaderV1());

  deleteSvKeys = (customerId: number, svKeyId: number): Observable<string> => this.http.delete<string>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/sv-keys/${svKeyId}?id=${svKeyId}`, this.apiService.generateAuthHeaderV1());

  getLicences = (customerId: number): Observable<Licence[]> => this.http.get<Licence[]>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/licences`, this.apiService.generateAuthHeaderV1());

  deleteLicence = (customerId: number, licenceId: number): Observable<string> => this.http.delete<string>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/licences/${licenceId}`, this.apiService.generateAuthHeaderV1());

  createLicences = (customerId: number, licence: Licence): Observable<Licence> => this.http.post<Licence>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/licences`, {data: JSON.stringify(licence), customer: customerId}, this.apiService.generateAuthHeaderV1());

  updateLicence = (customerId: number, licence: Licence): Observable<Licence> => this.http.put<Licence>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/licences/${licence.id}`, {data: JSON.stringify(licence)}, this.apiService.generateAuthHeaderV1());    

  updateMultipleLicence = (customerId: number, multipleLicence: MultipleLicence): Observable<string> => this.http.put<string>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/licences/several`, {data: JSON.stringify(multipleLicence)}, this.apiService.generateAuthHeaderV1()); 

  getActiveLicences = (customerId: number): Observable<ActiveLicence[]> => this.http.get<ActiveLicence[]>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/licences/active`, this.apiService.generateAuthHeaderV1());

  associateActiveLicencesKey = (customerId: number, licenceId: number, keyId: number): Observable<string> => this.http.post<string>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/licences/${licenceId}/sv-keys/${keyId}/associate`, 
    {licence: licenceId, svKey: keyId, old_licence: null}, this.apiService.generateAuthHeaderV1());

  dissociateActiveLicencesKey = (customerId: number, licenceId: number|undefined, keyId: number): Observable<string> => this.http.post<string>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/licences/${licenceId}/sv-keys/${keyId}/dissociate`, 
    {licence: licenceId, svKey: keyId}, this.apiService.generateAuthHeaderV1());

  getAvailableLicencesKey = (customerId: number, keyId: number): Observable<Licence[]> => this.http.get<Licence[]>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/licences/sv-keys/${keyId}/available`, this.apiService.generateAuthHeaderV1());

  getActiveKeys = (customerId: number): Observable<ActiveLicence[]> => this.http.get<ActiveLicence[]>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/sv-keys/active`, this.apiService.generateAuthHeaderV1());
  
  getsvKeysType = (customerId: number): Observable<SvKeyType[]> => this.http.get<SvKeyType[]>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/sv-keys/types`, this.apiService.generateAuthHeaderV1());

  getExpiredLicences = (customerId: number): Observable<Licence[]> => this.http.get<Licence[]>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/licence/expire`, this.apiService.generateAuthHeaderV1());

  getNormalLicences = (customerId: number): Observable<Licence[]> => this.http.get<Licence[]>(
    `${environment.commonBaseUrl}/licence/v2/customers/${customerId}/licences/normal`, this.apiService.generateAuthHeaderV1());
  
  getVersions = (): Observable<AuthorizedVersion[]> => this.http.get<AuthorizedVersion[]>(
    `${environment.commonBaseUrl}/licence/v2/versions`, this.apiService.generateAuthHeaderV1());

  addVersions = (nbVersion: string): Observable<string> => this.http.post<string>(
    `${environment.commonBaseUrl}/licence/v2/versions`, {version: nbVersion}, this.apiService.generateAuthHeaderV1());

  deleteVersions = (versionId: number): Observable<string> => this.http.delete<string>(
      `${environment.commonBaseUrl}/licence/v2/versions/${versionId}`, this.apiService.generateAuthHeaderV1());
  
  getConnectionHistory = (customerId: number, endDate: string, startDate: string): Observable<ConnectionHistory[]> => this.http.get<ConnectionHistory[]>(
    `${environment.commonBaseUrl}/client/v2/connections/history/customers/${customerId}?endDate=${endDate}&startDate=${startDate}`, this.apiService.generateAuthHeaderV1());

  getCustomerConnectionHistory = (customerId: number, endDate: string, startDate: string): Observable<ConnectionCustomerHistory[]> => this.http.get<ConnectionCustomerHistory[]>(
    `${environment.commonBaseUrl}/client/v2/stats/${customerId}?end=${endDate}&start=${startDate}&csv=json`, this.apiService.generateAuthHeaderV1());

  setNbLicence = (nbLicence: number) => this.nbLicence = nbLicence;

  getNbLicence = () => this.nbLicence;
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessStatus, Category, Customer, MosServer } from 'src/app/admin/interfaces/customer.interface';
import { CustomersService } from 'src/app/admin/services/customers.service';
import { UiService } from 'src/app/utils/ui.service';
@Component({
  selector: 'edit-customer-dialog',
  templateUrl: './edit-customer-dialog.component.html',
  styleUrls: ['./edit-customer-dialog.component.less']
})
export class EditCustomerDialogComponent implements OnInit{
  categoryList: Category[] = [];
  statusList: BusinessStatus[] = [];
  mosServerList: MosServer[] = [];
  nameFormControl: FormControl = new FormControl('', [Validators.required]);

  constructor(public dialogRef: MatDialogRef<EditCustomerDialogComponent>, @Inject(MAT_DIALOG_DATA) public customer: Customer, 
              private customerService: CustomersService, private uiService: UiService, public editDialog: MatDialog) {
  }

  ngOnInit(): void {
    if(!this.customer){
      this.customer = {
        id: 0,
        name: '',
        status: true,
        exhibition: false,
        nbLicence: 0,
        contactUsers:	[],
        mosServer: {
          id: 0,
          name: '',
          address: ''},
        businessStatus: {
          id: 2,
          status: 'eval',
          isDefault: true
        },
        category: {
          id: 5,
          category: 'Other',
          isDefault: true}
      };
    }
    this.customer.contactUsers = [];
    this.customerService.getStatus().subscribe({
      next : (status: BusinessStatus[]) => {
        this.statusList = status;
      },
      error: () => {
        this.uiService.openSnackBar('Error fetching status', undefined);
      }}
    );
    this.customerService.getCategory().subscribe({
      next : (category: Category[]) => {
        this.categoryList = category;
      },
      error: () => {
        this.uiService.openSnackBar('Error fetching categories', undefined);
      }}
    );
    this.customerService.getMosServer().subscribe({
      next : (mosServers: MosServer[]) => {
        this.mosServerList = mosServers;
      },
      error: () => {
        this.uiService.openSnackBar('Error fetching mos server', undefined);
      }}
    );
  }

  compareId = (t1: any, t2: any): boolean => t1 && t2 && t1.id === t2.id;

  alreadyExists = (): boolean => this.customerService.isCustomerNameAlreadyExist(this.customer.name);
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utils/api.service';
import { environment } from '../../../environments/environment';
import { BusinessStatus, Category, Customer, MosServer } from '../interfaces/customer.interface';


@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  customerName: string[] = [];

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getCustomers = (): Observable<Customer[]> => this.http.get<Customer[]>(
    `${environment.commonBaseUrl}/client/v2/customers`, this.apiService.generateAuthHeaderV1());
  
  getCustomerById = (customerId: number): Observable<Customer> => this.http.get<Customer>(
    `${environment.commonBaseUrl}/client/v2/customers/${customerId}`, this.apiService.generateAuthHeaderV1());

  addCustomers = (customer: Customer): Observable<Customer> => this.http.post<Customer>(
    `${environment.commonBaseUrl}/client/v2/customers`,  {data: customer}, this.apiService.generateAuthHeaderV1());

  deleteCustomers = (customerId: number): Observable<string> => this.http.delete<string>(
    `${environment.commonBaseUrl}/client/v2/customers/${customerId}`, this.apiService.generateAuthHeaderV1());

  editCustomers = (customerId: number, customer: Customer): Observable<string>  => this.http.put<string>(
    `${environment.commonBaseUrl}/client/v2/customers/${customerId}`, {data: customer}, this.apiService.generateAuthHeaderV1());

  getStatus = (): Observable<BusinessStatus[]> => this.http.get<BusinessStatus[]>(
    `${environment.commonBaseUrl}/client/v2/statuses`, this.apiService.generateAuthHeaderV1());

  getCategory = (): Observable<Category[]> => this.http.get<Category[]>(
    `${environment.commonBaseUrl}/client/v2/categories`, this.apiService.generateAuthHeaderV1());

  getMosServer = (): Observable<MosServer[]> => this.http.get<MosServer[]>(
    `${environment.commonBaseUrl}/mos/v2/servers`, this.apiService.generateAuthHeaderV1());

  setCustomerName = (customerName: string[]): void => {
    this.customerName = customerName;
  };

  extractUserCsv = (customersId: number[]): Observable<any>  => this.http.get<any>(
    `${environment.commonBaseUrl}/client/v2/users/csv?customers=[${customersId.toString()}]`, this.apiService.generateAuthHeaderV1());

  isCustomerNameAvailable = (newName: string, oldName: string): boolean => !this.customerName.includes(newName) || newName === oldName; 

  isCustomerNameAlreadyExist = (newName: string): boolean => this.customerName.includes(newName); 

}

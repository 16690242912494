<h1 mat-dialog-title>Customer</h1>
<div mat-dialog-content>
  <div class="column">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput [formControl]="nameFormControl" [(ngModel)]="customer.name">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Number of licence</mat-label>
      <input matInput type="number" min="0" [(ngModel)]="customer.nbLicence">
    </mat-form-field>
    <mat-checkbox [(ngModel)]="customer.status">Status Active</mat-checkbox>
    <mat-form-field appearance="fill">
      <mat-label>Status</mat-label>
      <mat-select [(ngModel)]="customer.businessStatus" [compareWith]="compareId">
        <mat-option *ngFor="let s of statusList" [value]="s">
          {{s.status}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Category</mat-label>
      <mat-select [(ngModel)]="customer.category" [compareWith]="compareId">
        <mat-option *ngFor="let c of categoryList" [value]="c">
          {{c.category}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Mos Server</mat-label>
      <mat-select [(ngModel)]="customer.mosServer" [compareWith]="compareId">
        <mat-option *ngFor="let m of mosServerList" [value]="m">
          {{m.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null">No Thanks</button>
  <button mat-button [disabled]="nameFormControl.hasError('required') || alreadyExists()"  [mat-dialog-close]="customer" cdkFocusInitial>Ok</button>
</div>

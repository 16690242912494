import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-layer3',
  templateUrl: './layer3.component.html',
  styleUrls: ['./layer3.component.less']
})
export class Layer3Component {
  @Input() customerIdInput!: number;
}

import { Injectable } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { TabNames } from 'src/app/utils/constants';

@Injectable({
  providedIn: 'root',
})
export class TabNavigationService {
  selectTabByFragmentAdmin(tabGroup: MatTabGroup, fragment: string): void {
    switch (fragment) {
      case TabNames.Users:
        this.selectTab(tabGroup, 0);
        break;
      case TabNames.Licences:
        this.selectTab(tabGroup, 1);
        break;
      case TabNames.Storage:
        this.selectTab(tabGroup, 2);
        break;
      default:
        this.selectTab(tabGroup, 0);
        break;
    }
  }

  selectTabByFragmentUser(tabGroup: MatTabGroup, fragment: string): void {
    switch (fragment) {
      case TabNames.Licences:
        this.selectTab(tabGroup, 0);
        break;
      case TabNames.Storage:
        this.selectTab(tabGroup, 1);
        break;
      default:
        this.selectTab(tabGroup, 0);
        break;
    }
  }

  private selectTab(tabGroup: MatTabGroup, index: number): void {
    tabGroup.selectedIndex = index;
  }
}

<vws-loader [loading]="isLoading" mode="indeterminate"></vws-loader>
<div class="column-100">
<h2>
  Viser/Neo Connection History
</h2>

  <div [chart]="userChart" *ngIf="userChart"></div>

  <div *ngIf="connectionHistoryList" class="width-100">
    <mat-paginator [pageSizeOptions]="[20, 50, 100]" [pageSize]="50" class="paginator-color"
    showFirstLastButtons 
    aria-label="Select page of licences">
    </mat-paginator>
    <table mat-table [dataSource]="connectionHistoryList" matSort class="table-margin mat-elevation-z4 width-100">
       
        <!-- ID Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let conHistory">
                  {{conHistory.id}}
            </td>
        </ng-container>
  
        <ng-container matColumnDef="date" >
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let conHistory" >{{conHistory.con_date }}
            </td>
        </ng-container>
        
        <ng-container matColumnDef="viserVersion" >
            <th mat-header-cell *matHeaderCellDef>Viser Version</th>
            <td mat-cell *matCellDef="let conHistory" >{{conHistory.viser_version}}</td>
        </ng-container>
        
        <ng-container matColumnDef="licenceId" >
            <th mat-header-cell *matHeaderCellDef> Licence ID </th>
            <td mat-cell *matCellDef="let conHistory">
              {{conHistory.licence_id}}
            </td>
        </ng-container>
  
        <!-- Proxy Column -->
        <ng-container matColumnDef="IMSI" >
          <th mat-header-cell *matHeaderCellDef> IMSI ID </th>
          <td mat-cell *matCellDef="let conHistory"> 
            {{conHistory.imsi_id}}
          </td>
      </ng-container>
  
        <!-- MSISDN Column -->
        <ng-container matColumnDef="IMEI" >
          <th mat-header-cell *matHeaderCellDef> IMEI ID  </th>
          <td mat-cell *matCellDef="let conHistory">
            {{conHistory.imei_id}}
          </td>
      </ng-container>
  
      <!-- Licence Column -->
      <ng-container matColumnDef="UDID" >
        <th mat-header-cell *matHeaderCellDef> UDID ID </th>
        <td mat-cell *matCellDef="let conHistory">
          {{conHistory.udid_id}}
        </td>
      </ng-container>
  
        <!-- MSISDN Column -->
        <ng-container matColumnDef="UNIQUE" >
          <th mat-header-cell *matHeaderCellDef> UNIQUE ID </th>
          <td mat-cell *matCellDef="let conHistory">
            {{ conHistory.unique_id }} </td>
      </ng-container>
  
      <!-- Licence Column -->
      <ng-container matColumnDef="error" >
        <th mat-header-cell *matHeaderCellDef> Error </th>
        <td mat-cell *matCellDef="let conHistory">{{conHistory.error}}</td>
      </ng-container>
  
        <!-- Actions Column -->
        <ng-container matColumnDef="request">
            <th mat-header-cell *matHeaderCellDef>Request</th>
            <td mat-cell *matCellDef="let conHistory">
              {{conHistory.request}}
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row-artifacts"
            [ngStyle]="{'cursor': 'pointer', 'font-weight': row.alreadyRead? 'normal' : 'bold'}">
        </tr>
    </table>
  </div>
</div>
<vws-navbar
*ngIf="!isAuth"
  [parentService]="$service"
  [subtitleChips]="false"
  [sticked]="true"
  [isDashboard]="isDashboard"
  [isUpload]="isUpload"
  (goToHome)="goToHome()"
  [subtitle]="''">
</vws-navbar>
<mat-sidenav-container>
  <mat-sidenav-content class="main-container">
      <div id="main-container">
        <router-outlet></router-outlet>
      </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<div>
  <h3>Imei List for Layer 3</h3>
  <div *ngIf="isAdmin">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput [formControl]="imeiFormControl">
    </mat-form-field>
    <button class="button-color" mat-raised-button (click)="addImei()">Add imei</button>
  </div>
  <table mat-table [dataSource]="listImeiTable" class="table-margin width-90">
    <!-- imei Column -->
    <ng-container matColumnDef="imei">
      <th mat-header-cell *matHeaderCellDef>Imei</th>
      <td mat-cell *matCellDef="let imei">
        {{imei.imei}}
      </td>
    </ng-container>
    <!-- actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let imei">
        <button mat-icon-button (click)="removeImei(imei)">
          <mat-icon>delete</mat-icon>
        </button>  
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="isAdmin ? displayedColumnsAdmin : displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: isAdmin ? displayedColumnsAdmin : displayedColumns;let imei;" class="mat-row-imei">
    </tr>
  </table>
  
</div>

<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      <a routerLink="#users">
        <mat-icon class="example-tab-icon">person</mat-icon>
        Users
      </a>
    </ng-template>
    <app-users class="margin-tab"></app-users>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <a routerLink="#licences">
        <mat-icon class="example-tab-icon">assignment</mat-icon>
        Licences
      </a>
    </ng-template>
    <app-licence-management class="margin-tab"></app-licence-management>
    <app-key-mnagement class="margin-tab"></app-key-mnagement>
    <app-layer3-admin class="margin-tab"></app-layer3-admin>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <a routerLink="#storage">
      <mat-icon class="example-tab-icon">cloud_download</mat-icon>
      Storage
      </a>
    </ng-template>
    <app-storage class="margin-tab"></app-storage>
  </mat-tab>
  <mat-tab *ngIf="hasUsagesKpi()">
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">bar_chart</mat-icon>
      Usage KPI
    </ng-template>
    <app-user-kpi></app-user-kpi>
  </mat-tab>
</mat-tab-group>

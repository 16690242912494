<h1 mat-dialog-title>Edit key name</h1>
<div mat-dialog-content>
  <div class="column">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput [(ngModel)]="svKey.simnickname">
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null">No Thanks</button>
  <button mat-button [mat-dialog-close]="svKey" cdkFocusInitial>Ok</button>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utils/api.service';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class KpiUserService {

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getUsersKpi = (dateStart: Date, dateEnd: Date, status: string[]|null): Observable<Highcharts.Options> => this.http.get<Highcharts.Options>(
    `${environment.baseUrl}/user/kpi?dateStart=${this.formatDate(dateStart)}&dateEnd=${this.formatDate(dateEnd)}&status=${status ? status.join(',') : ''}`, 
    this.apiService.generateAuthHeaderV2());
  
  getUsersCustomerKpi = (dateStart: Date, dateEnd: Date, customerId: string): Observable<Highcharts.Options> => this.http.get<Highcharts.Options>(
    `${environment.baseUrl}/user/kpi?dateStart=${this.formatDate(dateStart)}&dateEnd=${this.formatDate(dateEnd)}&customerId=${customerId}`, 
    this.apiService.generateAuthHeaderV2());

  formatDate = (date: Date): string => `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { UiService } from 'src/app/utils/ui.service';
import { RoleConstants } from '@smartviser/core-lib';
import { CustomerFiles } from 'src/app/admin/interfaces/storage.interface';
import { StorageService } from 'src/app/admin/services/storage.service';
import { UsersService } from 'src/app/admin/services/users.service';
import { Roles } from 'src/app/admin/interfaces/user.interface';
import { saveAs } from 'file-saver';


@Component({
  selector: 'files-table',
  templateUrl: './files-table.component.html',
  styleUrls: ['./files-table.component.less']
})
export class FilesTableComponent implements OnInit, OnChanges {
  @Input() customerIdInput!: number;
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  displayedColumns: string[] = [
    'name',
    'fileSize',
    'uploadDate',
    'actions'
  ];

  filesList: MatTableDataSource<CustomerFiles> = new MatTableDataSource<CustomerFiles>([]);
  customerId!: number;

  constructor(
    private storageService: StorageService,
    private usersService: UsersService,
    private uiService: UiService
  ) {}

  ngOnInit(): void {
    // If AdminSv or AdminSystem display User of customer instead dispaly user of the customer
    if (this.customerIdInput) {
      this.customerId = this.customerIdInput;
    } else {
      this.customerId = this.usersService.getCustomerId();
    }
    if(this.customerIdInput !== this.customerId){
      this._setListCustomerFiles();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerIdInput) {
      this.filesList.data = [];
      this.customerId = changes.customerIdInput.currentValue;
      this._setListCustomerFiles();
    }
  }

  isAdmin(): boolean {
    const roleUser: Roles | undefined = this.usersService.getUserRole();
    return RoleConstants.GROUPS.ADMINS.includes(roleUser?.role);
  }


  deleteCustomerFile(fileId: number): void {
    this.isLoading.emit(true);
    this.storageService.deleteCustomerFile(this.customerId, fileId).subscribe({
      next: () => {
        this._setListCustomerFiles();
        this.uiService.openSnackBar('File deleted', undefined);
      },
      error: () => {
        this.uiService.openSnackBar('Error deleting file', undefined);
      },
      complete: () => {
        this.isLoading.emit(false);
      }
    });
  }

  downloadCustomerFile(custFile: CustomerFiles): void {
    this.isLoading.emit(true);
    this.storageService.downloadCustomerFile(this.customerId, custFile.id).subscribe({
      next: (response: any) => {
        const blob: Blob = new Blob([response]);
        const fileOfBlob: File = new File([blob], `${custFile.filename}`);
        saveAs(fileOfBlob);
      },
      error: () => {
        this.uiService.openSnackBar('Error downloading files', undefined);
      },
      complete: () => {
        this.isLoading.emit(false);
      }
    });
  }


  handleFileUpload(eventTraget: EventTarget|null): void {
    this.isLoading.emit(true);
    const file: FileList| null = eventTraget ? (eventTraget as HTMLInputElement).files : null;
    if(file && file?.length > 0){
      const formData: FormData = new FormData();
      formData.append('file', file[0], file[0].name);
      formData.append('content-length', file[0].size.toString());
      this.storageService.uploadCustomerFile(this.customerId, formData).subscribe({
        next: () => {
          this._setListCustomerFiles();
        },
        complete: () => {
          this.isLoading.emit(false);
        },
        error: () => {
          this.uiService.openSnackBar('Error uploading file', undefined);
        }
      });
    }
  }

  isAdminSv = (): boolean => this.usersService.isAdminSv();

  _setListCustomerFiles(): void {
    this.isLoading.emit(true);
    this.storageService.getCustomersFilesList(this.customerId).subscribe({
      next: (customerFiles: CustomerFiles[]) => {
        this.filesList = new MatTableDataSource(customerFiles); 
      },
      complete: () => {
        this.isLoading.emit(false);
      }
    });
  }
}

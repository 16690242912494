import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SvKey } from 'src/app/admin/interfaces/licence.interface';
@Component({
  selector: 'edit-key-name-dialog',
  templateUrl: './edit-key-name-dialog.component.html',
  styleUrls: ['./edit-key-name-dialog.component.less']
})
export class EditKeyNameDialogComponent{

  svKey: SvKey;

  constructor(public dialogRef: MatDialogRef<EditKeyNameDialogComponent>, @Inject(MAT_DIALOG_DATA) public svkey: SvKey) {
    this.svKey = svkey;
  }

}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'valid-dialog',
  templateUrl: './valid-dialog.component.html',
  styleUrls: ['./valid-dialog.component.less']
})
export class ValidDialogComponent{

  constructor(public dialogRef: MatDialogRef<ValidDialogComponent>){}

}

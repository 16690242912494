import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { UiService } from 'src/app/utils/ui.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from '../../services/users.service';
import { LicencesService } from '../../services/licences.service';
import { Licence, LicenceDate, MultipleLicence } from '../../interfaces/licence.interface';
import { EditLicenceDialogComponent } from './licence-management/edit-licence-dialog/edit-licence-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ValidDialogComponent } from 'src/app/utils/shared-dialog/valid-dialog.component';
import { Customer } from '../../interfaces/customer.interface';
import { CustomersService } from '../../services/customers.service';

@Component({
  selector: 'app-licence-management',
  templateUrl: './licence-management.component.html',
  styleUrls: ['./licence-management.component.less']
})
export class LicencesManagementComponent implements OnInit, OnChanges {
  @Input() customer!: Customer;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumnsAdmin: string[] = [
    'select',
    'id',
    'startDate',
    'endDate',
    'synchroviser',
    'proxy',
    'mos',
    'auxCall',
    'authorizedVarsions',
    'key',
    'actions'
  ];

  displayedColumns: string[] = [
    'id',
    'startDate',
    'endDate',
    'synchroviser',
    'proxy',
    'mos',
    'auxCall',
    'authorizedVarsions',
    'key'
  ];

  licencesList: MatTableDataSource<Licence> = new MatTableDataSource<Licence>([]);
  isLoading: boolean = true;
  customerId!: number;
  search: string = '';
  nbLicence: number = 0;
  timezoneFr: any = { timeZone: 'Europe/Paris' };
  
  selection: SelectionModel<Licence> = new SelectionModel<Licence>(true, []);

  constructor(
    public licencesServices: LicencesService,
    public customerService: CustomersService,
    private usersService: UsersService,
    private uiService: UiService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // If AdminSv or AdminSystem display User of customer instead dispaly user of the customer
    if(this.customer){
      if (this.customer.id) {
        this.customerId = this.customer.id;
        this.nbLicence = this.customer.nbLicence;
      } else {
        this.customerId = this.usersService.getCustomerId();
        this.nbLicence = this.customer.nbLicence;
      }
      if(this.customer.id !== this.customerId){
        this._setLicenceCustomer();
      }
    }else{
      this.customerId = this.usersService.getCustomerId();
      this._setLicenceCustomer();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customer) {
      this.licencesList.data = [];
      this.customer = changes.customer.currentValue;
      this.customerId = this.customer.id;
      this.nbLicence = this.customer.nbLicence;
      this._setLicenceCustomer();
      this.search = '';
    }
  }

  applyFilter(event: Event): void {
    const filterValue: string = (event.target as HTMLInputElement).value;
    this.licencesList.filter = filterValue.trim().toLowerCase();
  }

  createLicence(): void {
    const dialogRef: MatDialogRef<EditLicenceDialogComponent> = this.dialog.open(
      EditLicenceDialogComponent,
      {
        minWidth: 600,
        data: { customerId: this.customerId, isEdit: false }
      }
    );
    dialogRef.afterClosed().subscribe((licenceAddd: any) => {
      if (licenceAddd) {
        this.isLoading = true;
        // be compatible with v1 should change in v2
        licenceAddd.startDate =   licenceAddd.startDate.toLocaleDateString();
        licenceAddd.endDate = licenceAddd.endDate.toLocaleDateString();
        this.licencesServices.createLicences(this.customerId, licenceAddd).subscribe({
          next: () => {
            this.uiService.openSnackBar('licences added', undefined);
            this._setLicenceCustomer();
            this.isLoading = false;
          },
          error: () => {
            this.isLoading = false;
          }
        });
      }
    });
  }

  isAdminSv = (): boolean => this.usersService.isAdminSv();

  isLicenceExpired = (date: string): boolean => new Date(date) < new Date();

  updateTable(): void {
    this._setLicenceCustomer();
  }

  _setLicenceCustomer(): void {
    this.isLoading = true;
    this.selection.clear();
    this.licencesServices.getLicences(this.customerId).subscribe({
      next: (licences: Licence[]) => {
        this.licencesServices.setNbLicence(licences.length);
        this.licencesList = new MatTableDataSource(licences);
        this.licencesList.sort = this.sort;
        this.licencesList.paginator = this.paginator;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.uiService.openSnackBar('Error fetching users', undefined);
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected: number = this.selection.selected.length;
    const numRows: number = this.licencesList.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.licencesList.data.forEach((row: Licence) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Licence): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }


  deleteLicence(licences: Licence): void {
    const dialogRef: MatDialogRef<ValidDialogComponent> =  this.dialog.open(ValidDialogComponent, {
      minWidth: 600
    });
    dialogRef.afterClosed().subscribe((shouldDelete: boolean) => {
      if(shouldDelete){
        this.isLoading = true;
        this.licencesServices.deleteLicence(this.customerId, licences.id).subscribe({
          next: () => {
            this.uiService.openSnackBar('Licences deleted', undefined);
            this._setLicenceCustomer();
          },
          error: () => {
            this.isLoading = false;
            this.uiService.openSnackBar('Error deleting licences', undefined);
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      }
    });
  }

  ediLicenceDialog(licence: Licence): void {
    const copyLicence: Licence = JSON.parse(JSON.stringify(licence));
    const dialogRef: MatDialogRef<EditLicenceDialogComponent> = this.dialog.open(
      EditLicenceDialogComponent,
      {
        minWidth: 600,
        data: { licence: copyLicence, customerId: this.customerId, isEdit: true }
      }
    );
    dialogRef.afterClosed().subscribe((licenceEdit: any) => {
      if (licenceEdit) {
        licenceEdit.startDate =   licenceEdit.startDate.toLocaleDateString('fr', this.timezoneFr);
        licenceEdit.endDate = licenceEdit.endDate.toLocaleDateString('fr', this.timezoneFr);
        this.licencesServices.updateLicence(this.customerId, licenceEdit).subscribe({
          next: () => {
            this.uiService.openSnackBar('licence updated', undefined);
            this._setLicenceCustomer();
          },
          error: () => {
            this.isLoading = false;
            this.uiService.openSnackBar('Error updating licence', undefined);
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      }
    });
  }

  editMultipleLicences(): void {
    const dialogRef: MatDialogRef<EditLicenceDialogComponent> = this.dialog.open(
      EditLicenceDialogComponent,
      {
        minWidth: 600,
        data: { customerId: this.customerId, isEdit: true, licences: this.selection.selected}
      }
    );
    dialogRef.afterClosed().subscribe((licenceEdit: any) => {
      if (licenceEdit) {
        // Update licence one by one to not update start date
        const licenceEndDate: string = licenceEdit.endDate.toLocaleDateString('fr', this.timezoneFr);
        this.selection.selected.forEach((licence: Licence) => {
          licence.endDate = licenceEndDate;
          const licenceDate: LicenceDate = licence.startDate as LicenceDate;
          licence.startDate = new Date(licenceDate.date).toLocaleDateString('fr', this.timezoneFr);
          licence.id = licence.id;
          licence.proxy = licenceEdit.proxy;
          licence.synchroviser = licenceEdit.synchroviser;
          licence.auxCall = licenceEdit.auxCall;
          licence.mos = licenceEdit.mos;
          this.licencesServices.updateLicence(this.customerId, licence).subscribe({
            next: () => {
              this.uiService.openSnackBar('licence updated', undefined);
              this._setLicenceCustomer();
            },
            error: () => {
              this.isLoading = false;
              this.uiService.openSnackBar('Error updating licence', undefined);
            },
            complete: () => {
              this.isLoading = false;
            }
          });
        });
      }
    });
  }


  editNumberLicenceCustomer(): void{
    this.isLoading = true;
    this.customer.nbLicence = this.nbLicence;
    this.customer.contactUsers = this.customer.contactUsers ? this.customer.contactUsers : []; 
    this.customerService.editCustomers(this.customer.id, this.customer).subscribe({
      next : () => {
        this.customer.nbLicence = this.nbLicence;
        this.uiService.openSnackBar('Customer edited', undefined);
      },
      error: () => {
        this.isLoading = false;
        this.uiService.openSnackBar('Error editing customer', undefined);
      },
      complete: () => {
        this.isLoading = false;
      }}
    );
  }

  isNotValidnumberLicence = (): boolean => this.nbLicence <= this.licencesServices.getNbLicence();

}

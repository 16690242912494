import { Injectable } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  createMainChart = (options: Highcharts.Chart|Options): Chart => new Chart(options as Options);
}

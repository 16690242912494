<h3>Logs for L3</h3>
<table mat-table [dataSource]="logsList" class="table-margin width-90">
  <!-- Customer Column -->
  <ng-container matColumnDef="customer">
    <th mat-header-cell *matHeaderCellDef>Customer</th>
    <td mat-cell *matCellDef="let log">
      {{log.customer}}
    </td>
  </ng-container>
  <!-- User Column -->
  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef>User</th>
    <td mat-cell *matCellDef="let log">
      {{log.user}}
    </td>
  </ng-container>
  <!-- imei Column -->
  <ng-container matColumnDef="imei">
    <th mat-header-cell *matHeaderCellDef>Imei</th>
    <td mat-cell *matCellDef="let log">
      {{log.imei}}
    </td>
  </ng-container>
  <!-- status Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let log">
      <mat-icon *ngIf="log.status">done</mat-icon>
      <mat-icon *ngIf="!log.status">close</mat-icon>
    </td>
  </ng-container>
  <!-- date Column -->
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let log">
      {{log.dateTime}}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;let log;" class="mat-row-log">
  </tr>
</table>

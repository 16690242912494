

<div class="column-80">
<div class="row-100">
  <div class="width-20">
    <button class="button-storage" *ngIf="isAdminSv()" mat-raised-button color="primary" id="button-container-upload"(click)="fileInput.click()">
      Select file to upload
      <input #fileInput type="file" (change)="handleFileUpload($event.target)" style="display:none;" />
    </button>
  </div>
</div>

<div *ngIf="filesList" class="width-90 margin-files-table">
<table mat-table [dataSource]="filesList" matSort class="table-margin mat-elevation-z4 width-100">
  <!-- Checkbox Column -->
   <!-- ID Column -->
   <ng-container matColumnDef="name">
       <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
       <td mat-cell *matCellDef="let file">
             {{file.filename}}
       </td>
   </ng-container>


   <ng-container matColumnDef="fileSize" >
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Size</th>
    <td mat-cell *matCellDef="let file" >{{file.fileSize | metrics }}
    </td>
  </ng-container>

   <!-- Start Date -->
   <ng-container matColumnDef="uploadDate" >
       <th mat-header-cell *matHeaderCellDef mat-sort-header>Upload</th>
       <td mat-cell *matCellDef="let file" >{{file.uploadDate}}
       </td>
   </ng-container>

   <!-- Actions Column -->
   <ng-container matColumnDef="actions">
       <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
       <td mat-cell *matCellDef="let file">
         <ul (click)="$event.stopPropagation()">
         <li>
             <button mat-icon-button (click)="downloadCustomerFile(file)" matTooltip="Download">
                 <mat-icon>download</mat-icon>
             </button>
         </li>
         <li *ngIf="isAdminSv()" >
           <button mat-icon-button (click)="deleteCustomerFile(file.id)" matTooltip="Delete">
               <mat-icon>delete</mat-icon>
           </button>
         </li>
       </ul>
     </td>
   </ng-container>

   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
   <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row-artifacts"
       [ngStyle]="{'cursor': 'pointer', 'font-weight': row.alreadyRead? 'normal' : 'bold'}">
   </tr>
</table>
</div>
</div>
import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-background',
  templateUrl: './auth-background.component.html',
  styleUrls: ['./auth-background.component.less']
})
export class AuthBackgroundComponent {

}

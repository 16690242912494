import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DurationPipe } from './duration.pipe';
import { LicenceDatePipe } from './date.pipe';
import { MetricsPipe } from './metrics.pip';

@NgModule({
  declarations: [DurationPipe, LicenceDatePipe, MetricsPipe],
  exports: [DurationPipe, LicenceDatePipe, MetricsPipe],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }

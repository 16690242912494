/* eslint-disable @typescript-eslint/naming-convention */
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService, User } from '@smartviser/core-lib';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  private token: string = '';

  constructor(private cookieService: CookieService) {
    const userConnected: User|null = this.cookieService.getUserConnected();
    if(userConnected){
      this.token = userConnected.token;
    }
  }

  public generateAuthHeaderV1(): {headers: HttpHeaders}{
    return {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'X-AUTH-TOKEN': this.token
      })
    };
  }

  public generateAuthHeaderV1File(): {headers: HttpHeaders}{
    return {
      headers: new HttpHeaders({
        'Accept': 'application/json, text/plain, */*',
        'X-AUTH-TOKEN': this.token
      })
    };
  }

  public generateAuthHeaderV2(): {headers: HttpHeaders}{
    return {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'authentication': this.token
      })
    };
  }

  public generateAuthHeaderV2File(): {headers: HttpHeaders}{
    return {
      headers: new HttpHeaders({
        Accept: 'application/json, text/plain, */*',
        authentication: this.token
      })
    };
  }
}

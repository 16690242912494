import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { UiService } from 'src/app/utils/ui.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from '../../services/users.service';
import { StorageService } from '../../services/storage.service';
import { CustomerStorageInfo } from '../../interfaces/storage.interface';
import { UpdateStorageDialogComponent } from './update-storage/update-storage-dialog.component';

@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.less']
})
export class StorageComponent implements OnInit, OnChanges {
  @Input() customerIdInput!: number;

  isLoading: boolean = true;
  customerId!: number;
  storageInfo!: CustomerStorageInfo;

  
  constructor(
    public storageServices: StorageService,
    private usersService: UsersService,
    private uiService: UiService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // If AdminSv or AdminSystem display User of customer instead dispaly user of the customer
    if (this.customerIdInput) {
      this.customerId = this.customerIdInput;
    } else {
      this.customerId = this.usersService.getCustomerId();
    }
    if(this.customerIdInput !== this.customerId){
      this._getCustomerSpace();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerIdInput) {
      this.customerId = changes.customerIdInput.currentValue;
      this._getCustomerSpace();
    }
  }

  updateCustomerSpace(): void {
    const dialogRef: MatDialogRef<UpdateStorageDialogComponent> = this.dialog.open(
      UpdateStorageDialogComponent,
      {
        minWidth: 600,
        data: this.storageInfo.maxStorage
      }
    );
    dialogRef.afterClosed().subscribe((newSize: number) => {
      if (newSize) {
        this.isLoading = true;
        this.storageServices.updateStorageCustomer(this.customerId, this.storageInfo.id, newSize).subscribe({
          next: () => {
            this._getCustomerSpace();
            this.uiService.openSnackBar('Storage size update', undefined);
          }
        });
      }
    });
  }

  isAdminSv = (): boolean => this.usersService.isAdminSv();

  _getCustomerSpace(): void {
    this.isLoading = true;
    this.storageServices.getStorageCustomerInfo(this.customerId).subscribe({
      next: (storageInfo: CustomerStorageInfo) => {
        this.storageInfo = storageInfo;
        this.isLoading = false;
      },
      error: () => {
        this.uiService.openSnackBar('Error getting storage info', undefined);
      }
    });
  }
}

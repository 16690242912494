
<app-auth-background> 
  <div fxFlex="100%" fxFlex.gt-sm="50%" id="login-form" fxLayout="row" fxLayoutAlign="center center">
    <form (keydown.enter)="$event.preventDefault();checkKeyDown()" *ngIf="!forgottenPassword">
      <h1>viSer Web Services</h1>
      <div class="login-form-inner">
        <div>
          <mat-form-field class="login-input" appearance="outline">
            <mat-label>Username</mat-label>
            <input name="Username" [(ngModel)]="login" matInput>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="login-input" appearance="outline">
            <mat-label>Password</mat-label>
            <input name="password" [(ngModel)]="password" matInput [type]="hide ? 'password' : 'text'">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div>
          <button (click)="connect()" mat-flat-button color="primary" [disabled]="isDisabled()">Log in</button>
        </div>
        <div>
          <button (click)="forgottenPassword = true" mat-button>Forgot your password ?</button>
        </div>
      </div>
    </form>
    <form (keydown.enter)="$event.stopPropagation();checkKeyDown()" *ngIf="forgottenPassword">
      <h2>Forgotten password</h2>
      <div class="login-form-inner">
        <div class="mail-recover-text">Enter the email address you used when you created your VWS account</div>
        <div>
          <mat-form-field class="login-input" appearance="outline">
            <mat-label>E-mail</mat-label>
            <input matInput [formControl]="mailInput" name="mail" [(ngModel)]="mail" type="email">
          </mat-form-field>
        </div>
        <div>
          <button (click)="sendPasswordRequest()" mat-flat-button color="primary" [disabled]="isDisabledResetPassword()">Send</button>
        </div>
        <div>
          <button (click)="forgottenPassword = false" mat-button>Back to login</button>
        </div>
      </div>
    </form>
  </div>
  <div fxFlex="0" fxFlex.gt-sm="50%" class="logo" fxLayout="row" fxLayoutAlign="center center">
    <img src="/assets/logo-smartviser.png">
  </div>
</app-auth-background>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RoleConstants, VWSService } from '@smartviser/core-lib';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utils/api.service';
import { environment } from '../../../environments/environment';
import { PasswordEmail, PasswordLink, Roles, Users } from '../interfaces/user.interface';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  customerId: number = 0;
  userRole?: Roles = undefined;
  listUserRole: Roles[] = [];

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getUsers = (customerId: number): Observable<Users[]> => this.http.get<Users[]>(
    `${environment.commonBaseUrl}/client/v2/customers/${customerId}/users`, this.apiService.generateAuthHeaderV1());

  getCustomerUsers = (customerId: number): Observable<Users[]> => this.http.get<Users[]>(
    `${environment.commonBaseUrl}/client/v2/customers/${customerId}/users`, this.apiService.generateAuthHeaderV1());

  getUsersPasswordLink = (): Observable<PasswordLink[]> => this.http.get<PasswordLink[]>(
    `${environment.commonBaseUrl}/authentication/v2/requests`, this.apiService.generateAuthHeaderV1());

  addUsers = (customerId: number, user: Users): Observable<Users> => this.http.post<Users>(
    `${environment.commonBaseUrl}/client/v2/customers/${customerId}/users`,  user, this.apiService.generateAuthHeaderV1());

  deleteUsers = (customerId: number, userId: number): Observable<string> => this.http.delete<string>(
    `${environment.commonBaseUrl}/client/v2/customers/${customerId}/users/${userId}`, this.apiService.generateAuthHeaderV1());

  editUsers = (customerId: number, userId: number, user: Users): Observable<string>  => this.http.put<string>(
    `${environment.commonBaseUrl}/client/v2/customers/${customerId}/users/${userId}`, user, this.apiService.generateAuthHeaderV1());

  getUserRoles = (): Observable<Roles[]> => this.http.get<Roles[]>(
    `${environment.commonBaseUrl}/core/v2/roles`, this.apiService.generateAuthHeaderV1());

  getVwsServices = (): Observable<VWSService[]>  => this.http.get<VWSService[]>(
    `${environment.commonBaseUrl}/core/v2/services`, this.apiService.generateAuthHeaderV1());

  sendRequestPasswordEmail = (requestId: string, request: PasswordEmail): Observable<string>  => this.http.post<string>(
    `${environment.commonBaseUrl}/authentication/v2/requests/${requestId}/resend`, request, this.apiService.generateAuthHeaderV1());

  checkEmail = (email: string): Observable<Users[]>  => this.http.get<Users[]>(
    `${environment.commonBaseUrl}/client/v2/emails?email=${email}`, this.apiService.generateAuthHeaderV1());

  setCustomerId = (customerId: number): void => {this.customerId = customerId; };

  getCustomerId = (): number => this.customerId;

  setUserRole = (roles: Roles[], userRole: string): void => { 
    this.listUserRole = roles;
    this.userRole = roles.find((r: Roles) => r.role === userRole); 
  };

  getUserRole = (): Roles|undefined => this.userRole;

  getListUserRole = (): Roles[] => this.listUserRole;

  isAdminSv(): boolean {
    const roleUser: Roles | undefined = this.getUserRole();
    return RoleConstants.GROUPS.SV_ADMINS.includes(roleUser?.role);
  }

  isAdminSystem(): boolean {
    const roleUser: Roles | undefined = this.getUserRole();
    return RoleConstants.ADMIN_SYSTEM === roleUser?.role;
  }

}

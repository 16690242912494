import { OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';
import { TabNavigationService } from 'src/app/admin/services/tab-navigation.service'; import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.less']
})
export class UserPageComponent implements OnInit, AfterViewInit {
  @Input() VwsServices: number[] = [];

  @ViewChild(MatTabGroup, { static: false }) tabGroup!: MatTabGroup;

  constructor(private route: ActivatedRoute, private tabNavigationService: TabNavigationService) { }

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment !== null) {
        this.tabNavigationService.selectTabByFragmentUser(this.tabGroup, fragment);
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.route.snapshot.fragment != null) {
      this.tabNavigationService.selectTabByFragmentUser(this.tabGroup, this.route.snapshot.fragment);
    }
  }
  hasLicence = (): boolean => this.VwsServices.some((vwsS: number) => vwsS === 2);
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'licenceDate'
})
export class LicenceDatePipe implements PipeTransform {

  transform(value: string): string {
    const dateSplit: string[] = value.toString().split(' ')[0].split('-');
    return `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`;
  }

}

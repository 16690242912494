<h1 mat-dialog-title>Pick Licence</h1>
<div mat-dialog-content>
  <div *ngIf="licencesList && isAvailableLicences" class="m-2 width-100">
    <table mat-table [dataSource]="licencesList" matSort class="table-margin mat-elevation-z4 width-100">
      <ng-container matColumnDef="radio">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let licence">
          <mat-radio-group [(ngModel)]="licenceSelected">
            <mat-radio-button [value]="licence"></mat-radio-button>
          </mat-radio-group>
        </td>
      </ng-container>
        <!-- Start Date -->
        <ng-container matColumnDef="startDate" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
            <td mat-cell *matCellDef="let licence" >
              <ng-container *ngIf="licence.startDate.date">{{licence.startDate.date | licenceDate}}</ng-container>
            </td>
        </ng-container>
        
        <!-- End Date -->
        <ng-container matColumnDef="endDate" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
            <td mat-cell *matCellDef="let licence" >
              <ng-container *ngIf="licence.endDate.date">{{licence.endDate.date | licenceDate}}</ng-container>
            </td>
        </ng-container>
        
        <!-- Synchroviser Column -->
        <ng-container matColumnDef="synchroviser" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Synchroviser </th>
            <td mat-cell *matCellDef="let licence"> {{licence.synchroviser}} </td>
        </ng-container>
  
        <!-- Proxy Column -->
        <ng-container matColumnDef="proxy" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Proxy </th>
          <td mat-cell *matCellDef="let licence"> {{licence.proxy}} </td>
      </ng-container>
  
        <!-- MOS Column -->
        <ng-container matColumnDef="mos" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header> MOS  </th>
          <td mat-cell *matCellDef="let licence">{{ licence.mos }}</td>
      </ng-container>
  
      <!-- Aux call Column -->
      <ng-container matColumnDef="auxCall" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Aux call </th>
        <td mat-cell *matCellDef="let licence">{{ licence.auxCall }}</td>
      </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row-artifacts"
            [ngStyle]="{'cursor': 'pointer', 'font-weight': row.alreadyRead? 'normal' : 'bold'}">
        </tr>
    </table>
  </div>
  <div *ngIf="!isAvailableLicences">
    <p> No Licences Available </p>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null">No Thanks</button>
  <button mat-button [mat-dialog-close]="licenceSelected" cdkFocusInitial>Ok</button>
</div>

import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Customer } from '../../interfaces/customer.interface';
import { MatTableDataSource } from '@angular/material/table';
import { CustomersService } from '../../services/customers.service';
import { UiService } from 'src/app/utils/ui.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditCustomerDialogComponent } from './edit-customer-dialog/edit-customer-dialog.component';
import { MatSort } from '@angular/material/sort';
import { UsersService } from '../../services/users.service';
import { VersionDialogComponent } from '../version/version-dialog.component';
import { ValidDialogComponent } from 'src/app/utils/shared-dialog/valid-dialog.component';
import { ExtractUserDialogComponent } from 'src/app/utils/extract-user/extract-user.component';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.less']
})
export class CustomersComponent implements OnInit {

  @Output() customerSelected: EventEmitter<Customer> = new EventEmitter<Customer>();
  
  @ViewChild(MatSort) sort!: MatSort;
  
  displayedColumns: string[] = ['name', 'nbLicence', 'active', 'status', 'category', 'Mos server', 'Action'];

  customersList: MatTableDataSource<Customer> = new MatTableDataSource<Customer>([]);
  isLoading: boolean = true;

  constructor(
    private customerService: CustomersService, 
    private uiService: UiService,
    public userSevice: UsersService,
    public dialog: MatDialog
  ){
  }

  ngOnInit(): void {
    this.isLoading = true;
    this._getCustomers();
  }

  applyFilter(event: Event): void {
    const filterValue: string = (event.target as HTMLInputElement).value;
    this.customersList.filter = filterValue.trim().toLowerCase();
  }

  deleteCustomer(customer: Customer): void{
    const dialogRef: MatDialogRef<ValidDialogComponent> =  this.dialog.open(ValidDialogComponent, {
      minWidth: 600
    });
    dialogRef.afterClosed().subscribe((shouldDelete: boolean) => {
      this.isLoading = true;
      if(shouldDelete){
        this.customerService.deleteCustomers(customer.id).subscribe({
          next : () => {
            this.uiService.openSnackBar('Customers deleted', undefined);
            this._getCustomers();
          },
          error: () => {
            this.uiService.openSnackBar('Error deleting customer', undefined);
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      }
    });
  }

  createCustomer(): void{
    const dialogRef: MatDialogRef<EditCustomerDialogComponent> =  this.dialog.open(EditCustomerDialogComponent, {
      minWidth: 600,
      data: undefined
    });
    dialogRef.afterClosed().subscribe((customerAdd: Customer) => {
      if(customerAdd){
        this.isLoading = true;
        this.customerService.addCustomers(customerAdd).subscribe({
          next : (customer: Customer) => {
            this._getCustomers();
            this.openSidebar(customer);
          },
          error: () => {
            this.uiService.openSnackBar('Error editing customer', undefined);
          },
          complete: () => {
            this.isLoading = false;
          }}
        );
      }
    });
  }

  editCustomer(customer: Customer): void{
    const dialogRef: MatDialogRef<EditCustomerDialogComponent> =  this.dialog.open(EditCustomerDialogComponent, {
      minWidth: 600,
      data: customer
    });
    dialogRef.afterClosed().subscribe((customerEdit: Customer) => {
      if(customerEdit){
        this.isLoading = true;
        this.customerService.editCustomers(customer.id, customerEdit).subscribe({
          next : () => {
            this._getCustomers();
          },
          error: () => {
            this.uiService.openSnackBar('Error editing customer', undefined);
          },
          complete: () => {
            this.isLoading = false;
          }}
        );
      }
    });
  }

  extractUserCsv(): void{
    const dialogRef: MatDialogRef<ExtractUserDialogComponent> =  this.dialog.open(ExtractUserDialogComponent, {
      minWidth: 600,
      data: this.customersList.data
    });
    dialogRef.afterClosed().subscribe((customerUser: Customer[]) => {
      if(customerUser){
        this.isLoading = true;
        const customersId: number[] = customerUser.map((c: Customer) => c.id);
        this.customerService.extractUserCsv(customersId).subscribe({
          next : (response: any) => {
            const blob: Blob = new Blob([response['data']], {
                type: 'application/json'
            });
            const fileOfBlob: File = new File([blob], 'user_info.csv');
            saveAs(fileOfBlob);
          },
          error: () => {
            this.uiService.openSnackBar('Can not get user csv', undefined);
          },
          complete: () => {
            this.isLoading = false;
          }}
        );
      }
    });

  }

  manageVersion(): void{
    this.dialog.open(VersionDialogComponent, {
      minWidth: 600
    });
  }

  openSidebar(customer: Customer): void{
    this.customerSelected.emit(customer);
  }

  isAdminSystem(): boolean {
    return this.userSevice.isAdminSystem();
  }

  _getCustomers(): void{
    this.customerService.getCustomers().subscribe({
      next : (customers: Customer[]) => {
        this.customerService.setCustomerName(customers.map((c: Customer) => c.name));
        this.customersList = new MatTableDataSource(customers);
        this.customersList.sort = this.sort;
      },
      error: () => {
        this.uiService.openSnackBar('Error fetching customers', undefined);
      },
      complete: () => {
        this.isLoading = false;
      }}
    );
  }
}

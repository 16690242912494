import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Layer3Log } from 'src/app/admin/interfaces/layer3-imei.interface';
import { L3Service } from 'src/app/admin/services/layer3.service';

@Component({
  selector: 'app-layer3-logs',
  templateUrl: './layer3-logs.component.html',
  styleUrls: ['./layer3-logs.component.less']
})
export class Layer3LogsComponent implements OnInit {
  displayedColumns: string[] = ['customer', 'user', 'imei', 'date', 'status'];
  logsList: MatTableDataSource<Layer3Log> = new MatTableDataSource<Layer3Log>([]);

  constructor(private layer3Service: L3Service){
  }

  ngOnInit(): void {
    this.layer3Service.getListL3Logs().subscribe((logs: Layer3Log[]) => {
      this.logsList = new MatTableDataSource(logs);
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'metrics'
})
export class MetricsPipe implements PipeTransform {

  transform(value: number): string {
    if(value / 1000000000 >= 1){
      return `${(value / 1000000000).toFixed(2)} GB`;
    }else if(value / 1000000 >= 1){
      return `${(value / 1000000).toFixed(2)} MB`;
    }else if(value / 1000 >= 1){
      return `${(value / 1000).toFixed(2)} KB`;
    }else{
      return`${value} B`;
    }
  }

}

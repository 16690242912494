import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Customer } from 'src/app/admin/interfaces/customer.interface';
import { L3Service } from 'src/app/admin/services/layer3.service';

@Component({
  selector: 'app-layer3-list-customers',
  templateUrl: './layer3-list-customers.component.html',
  styleUrls: ['./layer3-list-customers.component.less']
})
export class Layer3ListCustomersComponent implements OnInit {
  displayedColumns: string[] = ['name'];
  customersList: MatTableDataSource<Customer> = new MatTableDataSource<Customer>([]);

  constructor(private layer3Service: L3Service){
  }

  ngOnInit(): void {
    this.layer3Service.getListL3Customers().subscribe((customers: Customer[]) => {
      this.customersList = new MatTableDataSource(customers);
    });
  }
}

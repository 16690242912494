<div class="column-100 margin-top">
  <mat-grid-list cols="3" rowHeight="3:1">
    <mat-grid-tile *ngFor="let service of allAccessibleServices">
      <a class="a-service" [href]="service.url" [target]="service.target">
        <div class="service-display">
          <div class="circle-icon">
            <mat-icon class="icon-display">{{service.icon}}</mat-icon>
          </div>
          <span class="service-name">{{service.name}}</span>
        </div>
      </a>
    </mat-grid-tile>
  </mat-grid-list>
</div>

import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {formatDate} from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { LicencesService } from '../../../services/licences.service';
import { ConnectionHistory } from 'src/app/admin/interfaces/licence.interface';
import { MatPaginator } from '@angular/material/paginator';
import { ChartService } from 'src/app/utils/chart.service';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-connection-history',
  templateUrl: './connection-history.component.html',
  styleUrls: ['./connection-history.component.less']
})
export class ConnectionHistoryComponent implements OnInit, OnChanges {
  @Input() customerIdInput!: number;
  @Input() startDate!: Date;
  @Input() endDate!: Date;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'id',
    'date',
    'viserVersion',
    'licenceId',
    'IMSI',
    'IMEI',
    'UDID',
    'UNIQUE',
    'error',
    'request'
  ];

  connectionHistoryList: MatTableDataSource<ConnectionHistory> = new MatTableDataSource<ConnectionHistory>([]);
  isLoading: boolean = true;
  customerId!: number;
  userChart: Chart|null = null;

  constructor(
    public licencesServices: LicencesService,
    private chartService: ChartService
  ) {}

  ngOnInit(): void {
    this.customerId = this.customerIdInput;
    this.getConnectionHistory();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerIdInput) {
      this.customerId = changes.customerIdInput.currentValue;
    }
    if (changes.endDate) {
      this.endDate = changes.endDate.currentValue;
    }
    if (changes.startDate) {
      this.startDate = changes.startDate.currentValue;
    }
    if(this.startDate && this.endDate){
      this.getConnectionHistory();
    }
  }

  getConnectionHistory(): void {
    this.isLoading = true;
    this.licencesServices.getConnectionHistory(this.customerId, this.endDate.toLocaleDateString(), this.startDate.toLocaleDateString()).subscribe({
      next: (conHis: ConnectionHistory[]) => {
        // Loop througth date to set nb connection history for bar graph
        let loop: Date = new Date(this.startDate);
        const allDate: string[] = [];
        const allCon: number[] = [];
        while (loop <= this.endDate) {
          const dateString: string = formatDate(loop, 'yyyy-MM-dd', 'en-US');
          const nbCon: number = conHis.filter((con: ConnectionHistory) => con.con_date.split('T')[0] === dateString).length;
          allDate.push(loop.toLocaleDateString());
          allCon.push(nbCon);
          loop = new Date(loop.setDate(loop.getDate() + 1));
        }
        this.userChart = this.buildConnectionHistoryChart(allDate, allCon);
        conHis.sort((a: ConnectionHistory, b: ConnectionHistory) => a.con_date > b.con_date ? -1 : 1);
        this.connectionHistoryList = new MatTableDataSource(conHis);
        this.connectionHistoryList.paginator = this.paginator;
        this.isLoading = false;
      }
    });
  }

  buildConnectionHistoryChart = (xDate: string[], yData: number[]): Chart => {
    const options: Highcharts.Options = {
      chart: {
        type: 'column'
      },
      xAxis : {
        categories: xDate,
        crosshair: true
      },
      title : {
        text: 'Connection per day'
      },
      yAxis: {
        tickInterval: 1
      },
      series: [{name: 'Nb Connection', data: yData, type: 'column'}]
    };

    return this.chartService.createMainChart(options);
  };

}

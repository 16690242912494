import { Component, Input } from '@angular/core';
import { Customer } from '../../interfaces/customer.interface';


@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.less']
})
export class AdminMenuComponent{

  @Input() customer!: Customer;

}

export enum Roles {
  Admin = 3,
  AdminSV = 2,
  AdminSystem = 1,
  Demo = 6,
  User = 5,
  UserSV = 4
}

export enum TabNames {
  Users = 'users',
  Licences = 'licences',
  Storage = 'storage'
}

export enum PasswordModifType {
  Create = 'create',
  Change = 'change'
}

export const customerStatus: Map<number, string> =  new Map<number, string>([
  [1, 'customer'],
  [2, 'eval'],
  [3, 'inactive'],
  [4, 'internal'],
  [5, 'customer exception']
]);

export const uiKeyUpdate: string = 'Keys update';
export const uiKeyUpdateError: string = 'Error updating sv Keys';

export const authPages: string[] = ['Login', 'Change password', 'Create password'];

export const regexValidationPw: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-.\/:;<>=?@^_`|~{}[\]])[A-Za-z\d!"#$%&'()*+,-.\/:;<>=?@^_`|~{}]{11,}/;


<vws-loader [loading]="isLoading" mode="indeterminate"></vws-loader>
<ng-container *ngIf="isAdminSv()">
  <h2>
    Space Used
  </h2>
  <div *ngIf="storageInfo">
    <p class="text-storage">Storage used {{storageInfo.usedStorage | metrics}} over {{storageInfo.maxStorage | metrics}}</p>
    <button class="button-storage" mat-raised-button color="primary" (click)="updateCustomerSpace()">Update storage space</button>
  </div>
</ng-container>
<div class="column-100">
  <h2>
    Private Files
  </h2>
  <files-table (isLoading)="isLoading = $event;" [customerIdInput]="customerId"></files-table>
</div>
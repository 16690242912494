<form [formGroup]="formGroup">
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input [disabled]="true" formControlName="name" matInput>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Surname</mat-label>
      <input [disabled]="true" formControlName="surname" name="surname" matInput>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Username</mat-label>
      <input [disabled]="true" formControlName="username" ngmo name="username" matInput>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input [disabled]="true" formControlName="email" name="email" matInput>
    </mat-form-field>
  </div>
  <!-- 
  <div>
    <button mat-flat-button color="primary">Submit</button>
  </div>
  -->
</form>
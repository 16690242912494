import { Component, OnInit } from '@angular/core';
import { CookieService, RoleConstants, User } from '@smartviser/core-lib';
import { UiService } from 'src/app/utils/ui.service';
import { Roles } from '../../interfaces/user.interface';
import { UsersService } from '../../services/users.service';


@Component({
  selector: 'app-first-page',
  templateUrl: './first-page.component.html',
  styleUrls: ['./first-page.component.less']
})
export class FirstPageComponent implements OnInit{

  userRole: Roles | undefined;
  userConnected!: User|null;
  isUser: boolean = false;
  isAdmin: boolean = false;
  isAdminSV: boolean = false;

  constructor(public userService: UsersService, public cookieService: CookieService, public uiService: UiService){
  }

  ngOnInit(): void {
    this.userConnected = this.cookieService.getUserConnected() as User;
    if([RoleConstants.USER_SV, RoleConstants.USER].includes(this.userConnected.roles)){
      this.userService.setUserRole([{role: 'user', id: 1}], 'user');
      this.userRole = this.userService.getUserRole();
      this.isUser = true;
    }else{
      this.userService.getUserRoles().subscribe({
        next: (roles: Roles[]) => {
          if (this.userConnected) {
            this.userService.setUserRole(roles, this.userConnected.roles);
            this.userRole = this.userService.getUserRole();
            this.isAdmin = this.userRole ? RoleConstants.ADMIN === this.userRole.role : false;
            this.isAdminSV = this.userRole ? RoleConstants.GROUPS.SV_ADMINS.includes(this.userRole.role) : false;
          }
        },
        error: () => {
          this.uiService.openSnackBar('Error fetching roles', undefined);
        }
      });
    }
  }

}

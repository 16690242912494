<mat-tab-group>
  <mat-tab *ngIf="hasLicence()">
    <ng-template mat-tab-label>
      <mat-icon>assignment</mat-icon>
      Licences
    </ng-template>
    
    <app-key-mnagement class="margin-tab"></app-key-mnagement>
    <app-licence-management class="margin-tab"></app-licence-management>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">cloud_download</mat-icon>
      Storage
    </ng-template>
    <app-storage class="margin-tab"></app-storage>
  </mat-tab>
</mat-tab-group>

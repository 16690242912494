import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';
import { TabNavigationService } from 'src/app/admin/services/tab-navigation.service';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.less'],
})
export class AdminPageComponent implements OnInit, AfterViewInit {
  @Input() VwsServices: number[] = [];

  @ViewChild(MatTabGroup, { static: false }) tabGroup!: MatTabGroup;

  constructor(private route: ActivatedRoute, private tabNavigationService: TabNavigationService) { }

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment !== null) {
        this.tabNavigationService.selectTabByFragmentAdmin(this.tabGroup, fragment);
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.route.snapshot.fragment != null) {
      this.tabNavigationService.selectTabByFragmentAdmin(this.tabGroup, this.route.snapshot.fragment);
    }
  }

  hasUsagesKpi = (): boolean => this.VwsServices.some((vwsS: number) => vwsS === 9);
}

import { Component } from '@angular/core';
import { LoginService } from './login.service';
import { User, CookieService } from '@smartviser/core-lib';
import { FormControl, Validators } from '@angular/forms';
import { UiService } from '../utils/ui.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent {
  login: string = '';
  password: string = '';
  error: string = '';
  forgottenPassword: boolean = false;
  mail: string = '';
  mailInput: FormControl = new FormControl('', [Validators.required, Validators.email]);
  hide: boolean = true;
  
  constructor(
    private loginService: LoginService,
    private cookieService: CookieService,
    private uiService: UiService
    ){}
  
  connect = (): void => {
    this.loginService.checkLoginForm(this.login, this.password).subscribe({
      next: (user: User) => this.logUser(user),
      error: (e: string) => this.error = e
    });
  };
  
  isDisabled = (): boolean => !this.login || !this.password;

  isDisabledResetPassword = (): boolean => this.mailInput.status !== 'VALID';
  
  logUser = (user: User): void => {
    this.cookieService.connectUser(user);
    window.location.href = '/';
  };
  
  checkKeyDown = (): void => {
    if(this.forgottenPassword){
      this.sendPasswordRequest();
    }else{
      this.connect();
    } 
  };

  sendPasswordRequest = (): void => {
    this.loginService.passwordResetRequest(this.mail).subscribe({
      next: (rep: any) => {
        this.uiService.openSnackBar('An email has been sent with a confirmation link', undefined);
        this.forgottenPassword = false;
        this.mailInput.reset();
      },
      error: () => {
        this.uiService.openSnackBar('No user found with this email', undefined);
      }
    });
  };
}
